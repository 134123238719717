export const state = () => ({
  gds: null,
});

export const getters = {
  getLocale(state) {
    return (state.gds?.nation || 'KR').toLowerCase();
  },
};

export const mutations = {
  set(state, gds) {
    state.gds = gds;
  },
};
