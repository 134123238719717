import { TV_REST_API_VERSION } from '@/constants/apiVersion';

export default ($axios) => ({
  /**
   * 로그인 사용자 정보 조회
   * @returns {Promise<AxiosResponse<T>>}
   */
  async getInfo(guestSq) {
    const url = `/api-tv/guest/${TV_REST_API_VERSION}/${guestSq}`;

    const { guest } = await $axios.get(url);
    return guest;
  },

  async postInfo() {
    const url = `/api-tv/guest/${TV_REST_API_VERSION}/`;

    const { guest } = await $axios.post(url, {});
    return guest;
  },

  async getGuestToken(roomSq) {
    const url = `/api-tv/room/${TV_REST_API_VERSION}/${roomSq}/guest`;

    const { guest } = await $axios.post(url, {});

    return guest;
  },
});
