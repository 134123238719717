import 'core-js';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'unorm';
import Cookies from 'cookie-universal';
import { DOMAIN } from '@/constants/cookies';

export default ({ req, res }, inject) => {
  const cookies = Cookies(req, res);
  if (!cookies.get(DOMAIN)) {
    const isDev = !!process.env.IS_LOCAL;
    const domain = 'http' + (isDev ? 's' : '') + `://${req.hostname}`;

    cookies.set(DOMAIN, domain, {
      path: '/',
      maxAge: 2147483647, // MAX
    });
  }
};
