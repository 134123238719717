import Cookies from 'cookie-universal';

const getRouteOption = (route, key) => {
  const isClient = process.client;
  let value = null;
  route.matched.some((m) => {
    if (isClient) {
      // Client
      return Object.values(m.components).some(({ options }) => {
        value = options && options[key];
        return value;
      });
    } else {
      // SSR
      return Object.values(m.components).some((component) =>
        Object.values(component._Ctor).some(({ options }) => {
          value = options && options[key];
          return value;
        })
      );
    }
  });
  return value;
};

const getMatchedComponents = (route, matches = []) => {
  return [].concat(
    [],
    route.matched.map(function (m, index) {
      return Object.keys(m.components).map((key) => {
        matches.push(index);
        return m.components[key];
      });
    })
  );
};

export default function ({ route, store, error, redirect, req, res }) {
  // Disable middleware if no route was matched to allow 404/error page
  const matches = [];
  const components = getMatchedComponents(route, matches);
  if (!components.length) {
    return;
  }

  const pageAuth = getRouteOption(route, 'auth');

  // TODO: 권한 처리
  if (pageAuth && !store.getters['user/memberNo']) {
    const cookie = Cookies(req, res);
    const domain = cookie.get('domain');
    redirect(`${process.env.LOGIN_URL}/auth/login?redirect_url=${encodeURIComponent(domain)}`);
  }
}
