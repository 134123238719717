import https from 'https';
import auth from '@/api/auth';
import room from '@/api/room';
import chatting from '@/api/chatting';
import member from '@/api/member';
import guest from '@/api/guest';
import youtube from '@/api/youtube';
import article from '@/api/article';
import common from '@/api/common';
import schedule from '@/api/schedule';
import terms from '@/api/terms';
import aiFilter from '@/api/aiFilter';
import gds from '@/api/gds';

export default (ctx, inject) => {
  const {
    $axios,
    // app: { $notify },
    redirect,
    error,
  } = ctx;

  // 로컬 서버인 경우
  if (process.env.IS_LOCAL) {
    $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });
  }

  const api = {
    auth: auth($axios),
    chatting: chatting($axios),
    room: room($axios),
    member: member($axios),
    guest: guest($axios),
    youtube: youtube($axios),
    article: article($axios),
    common: common($axios),
    schedule: schedule($axios),
    terms: terms($axios),
    aiFilter: aiFilter($axios),
    gds: gds($axios),
  };

  // FIXME: TV API header 변경시 삭제 - S
  $axios.onRequest((config) => {
    return config;
  });
  // FIXME: TV API header 변경시 삭제 - E

  $axios.onResponse((response) => {
    const { data } = response;
    const { response_code, result } = data;

    const status = response_code || result;

    // FIXME: API-TV 서버 오류 시 status 를 변경하지 않고 response_code를 사용
    // FIXME: AUTH-TV 서버 오류 시 status 를 변경하지 않고 result 사용
    if (['40103', '40104', '10100', '10000'].includes(`${status}`)) {
      // eslint-disable-next-line no-throw-literal
      throw {
        config: response.config,
        response: {
          ...response,
          status: 401,
        },
      };
    } else if (['50001', '50002', '50004', '50005', '50006'].includes(`${status}`)) {
      redirect('/msg/roomclose');
      return;
      /*
      return error({
        response_code: data.response_code,
        response_message: data.response_message,
      });
      // } else if (status && status === '558') {
      //   redirect('/')
      */
    } else if (
      // (status && status !== '000') ||
      typeof data === 'string' &&
      data.includes('ERROR')
    ) {
      console.debug('onRespone erorr #######', response);
      // eslint-disable-next-line no-throw-literal
      throw {
        config: response.config,
        response,
      };
    }

    return data;
  });

  $axios.onResponseError((error) => {
    console.groupCollapsed('######### $axios error ####################################');
    console.debug('error.config:', error);
    console.groupEnd();

    if (!error.response) {
      redirect('/');
    }

    return Promise.reject(error.response.data);
  });

  ctx.$api = api;
  inject('api', api);
  inject('apiStatus', {
    IDLE: 'IDLE',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
  });
};
