import { setGuestCookie } from '@/utils/auth';
function getGuestTemplate() {
  return {
    guestSq: null,
    regDt: null,
    updDt: null,
  };
}

const state = () => getGuestTemplate();

const getters = {
  guestSq: (state) => state.guestSq,
  regDt: (state) => state.regDt,
  updDt: (state) => state.updDt,
};

const mutations = {
  SET_GUEST: (state, { guestSq, regDt, updDt }) => {
    state.guestSq = guestSq;
    state.regDt = regDt;
    state.updDt = updDt;
  },
};

const actions = {
  async getInfo({ commit }, guestSq) {
    const $api = this.$api;
    let guest = getGuestTemplate();

    // guest 값이 있는지 확인
    const guestInfo = await $api.guest.getInfo(guestSq);
    guest = {
      guestSq: guestInfo.guest_sq,
      regDt: guestInfo.reg_dt,
      updDt: guestInfo.upd_dt,
    };
    commit('SET_GUEST', guest);

    return { guest };
  },

  async postInfo({ commit }, { req, res } = {}) {
    const $api = this.$api;
    let guest = getGuestTemplate();
    const guestInfo = await $api.guest.postInfo();

    guest = {
      guestSq: guestInfo.guest_sq,
      regDt: guestInfo.reg_dt,
      updDt: guestInfo.upd_dt,
    };

    setGuestCookie(guest.guestSq, { req, res });
    commit('SET_GUEST', guest);
    return guest;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
