export default ($axios) => ({
  // 'https://papi-sb.ppool.us/tos/v1.0/history?lang=KO&nation=KR&platform=PC&service_type=stove.game_id&sub_category=privacy&service_id=STOVETV_MOBILE'

  async getHistory(params) {
    const {
      data: { contents_list },
    } = await $axios.get(`${process.env.STOVE_TERMS_API}/tos/v1.0/history`, { params });

    return contents_list;
  },

  async getTerm(contentId) {
    const { data } = await $axios.get(`${process.env.STOVE_TERMS_API}/tos/v1.0/contents/${contentId}`);

    return data;
  },

  async getContents(params) {
    try {
      const {
        data: { contents_list },
      } = await $axios.get(`${process.env.STOVE_TERMS_API}/tos/v1.0/contents`, { params });

      return contents_list;
    } catch {
      return [];
    }
  },
});
