const state = () => {
  return {
    //
    /*
      member_no: String
      guest_sq: String
      producer_id: String
      type: String ( product, consume )
      zone: String(server url)
      producers: List
        producer_id
        kind ( audio / video )
        track ( MediaStreamTrack )
      stream: new MediaStream()
    */
    sources: [],
  };
};

const getters = {
  sources: (state) => state.sources,
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  editSource({ commit }, payload) {
    commit('editSource', payload);
  },
  pushSource({ commit }, payload) {
    commit('pushSource', payload);
  },
  deleteSource({ commit }, payload) {
    commit('deleteSource', payload);
  },
  enabledTrack({ commit }, payload) {
    commit('enabledTrack', payload);
  },
  pushTrack({ commit }, payload) {
    commit('pushTrack', payload);
  },
  deleteTrack({ commit }, payload) {
    commit('deleteTrack', payload);
  },
};

const mutations = {
  reset(state) {
    state.roomId = '';
    state.sources = [];
  },
  editSource(state, payload) {
    const { member_no, guest_sq, producer_id, kind, track, type } = payload;

    const prevInfo = state.sources.filter((source) => member_no === source.member_no && guest_sq === source.guest_sq);

    if (type === 'product') {
      const prevSameTrack = prevInfo[0].producers.filter((track) => track.kind === kind);
      if (kind === 'video') {
        const idx = state.sources.findIndex((source) => source.member_no === member_no && source.guest_sq === guest_sq);
        if (idx !== -1) {
          const trackIdx = state.sources[idx].producers.findIndex((track) => track.kind === kind);
          if (trackIdx !== -1) {
            const stream = prevInfo[0].stream;
            stream.removeTrack(prevSameTrack[0].track);
            stream.addTrack(track);
            state.sources[idx].producers.splice(trackIdx, 1, {
              kind,
              producer_id,
              track,
            });
          }
        }
      }
    }
  },
  pushSource(state, payload) {
    const { member_no, guest_sq, producer_id, kind, type, zone } = payload;
    const source = state.sources.find((s) => s.member_no === member_no && s.guest_sq === guest_sq);
    if (!source) {
      const data = {
        member_no,
        guest_sq,
        zone,
        type,
        producers: [
          {
            producer_id,
            kind,
            track: null,
          },
        ],
        stream: new MediaStream(),
      };
      if (type === 'product') state.sources.splice(0, 0, data);
      else state.sources.push(data);
    } else {
      source.producers.push({ producer_id, kind, track: null });
    }
  },
  deleteSource(state, payload) {
    const { guest_sq, member_no, producer_id } = payload;
    const source = state.sources.find((s) => s.member_no === member_no && s.guest_sq === guest_sq);

    if (source) {
      const idx = source.producers.findIndex((p) => p.producer_id === producer_id);
      if (idx !== -1) {
        source.producers[idx].track && source.stream.removeTrack(source.producers[idx].track);
        source.producers.splice(idx, 1);
      }

      if (source.producers.length === 0) {
        const sourceIdx = state.sources.findIndex((s) => s.member_no === member_no && s.guest_sq === guest_sq);

        if (sourceIdx !== -1) state.sources.splice(sourceIdx, 1);
      }
    }
  },
  enabledTrack(state, payload) {
    const { member_no, guest_sq, kind, track, type } = payload;

    if (type === 'product') {
      const idx = state.sources.findIndex((source) => source.member_no === member_no && source.guest_sq === guest_sq);
      if (idx !== -1) {
        const trackIdx = state.sources[idx].producers.findIndex((track) => track.kind === kind);
        if (trackIdx !== -1) {
          state.sources[idx].producers[trackIdx].track.enabled = track.enabled;
        }
      }
    }
  },
  pushTrack(state, payload) {
    const { member_no, guest_sq, producer_id, track, kind, type } = payload;
    const source = state.sources.find((s) => s.member_no === member_no && s.guest_sq === guest_sq);

    if (source) {
      const idx = source.producers.findIndex((p) => p.producer_id === producer_id);
      if (idx !== -1) {
        source.producers[idx].track = track;
        if (!(type === 'product' && kind === 'audio')) source.stream.addTrack(source.producers[idx].track);
      }
    }
  },
  deleteTrack(state, payload) {
    const { member_no, guest_sq, producer_id } = payload;
    const source = state.sources.find((s) => s.member_no === member_no && s.guest_sq === guest_sq);

    console.log('######## store', source);
    if (source) {
      const idx = source.producers.findIndex((p) => p.producer_id === producer_id);
      console.log('######## store producer idx', idx);
      if (idx !== -1) {
        source.producers[idx].track && source.stream.removeTrack(source.producers[idx].track);
        source.producers[idx].track = null;
      }
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
