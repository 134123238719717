import StompClient from '@/utils/stomp';
import { getInstance } from '@/utils/appDeepLink';
import { sendMessageToMobile } from '@/utils/webviewEventBus';

export default async (ctx, inject) => {
  const {
    store: { getters },
    $api,
  } = ctx;
  const isDesktop = window.orientation === undefined;

  const isAndroid = isDesktop ? false : /android|samsung/i.test(navigator.userAgent.toLocaleLowerCase());

  const isiOS = isDesktop ? false : /iphone|macintosh|ipad/i.test(navigator.userAgent.toLocaleLowerCase());

  const isIPad = isDesktop ? false : /ipad/i.test(navigator.userAgent.toLocaleLowerCase());

  const stomp = new StompClient();

  const memberNo = getters['user/memberNo'];
  const guestSq = getters['guest/guestSq'];

  inject('isDesktop', isDesktop);
  inject('isAndroid', isAndroid);
  inject('isiOS', isiOS);
  inject('isIPad', isIPad);

  const appDeepLink = getInstance($api, isAndroid, isiOS, getters);
  inject('moveToAppPage', appDeepLink.moveToAppPage);
  inject('moveToAppStore', appDeepLink.moveToAppStore);
  inject('sendMessageToMobile', sendMessageToMobile);
  inject('serviceName', process.env.SERVICE_NAME);
  // msg 연결이 필요할때만 처리 추가
  // watch ( mobile webview ), googledrive 제외건
  if (ctx.route.name.startsWith('watch') && !ctx.route.name.includes('googledrive')) {
    await stomp.connect('stovetv_user', 'stovetv_user&&7', memberNo, guestSq);
  }
  inject('stomp', stomp);
};
