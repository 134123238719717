import { TV_REST_API_VERSION } from '@/constants/apiVersion';
export default ($axios) => ({
  async getGlobalMessages() {
    // TODO: 글로벌 메시지, 버프 등등... 가져 오기
  },
  async getSettings(channelId = 'iris') {
    const params = { channel_id: channelId };
    console.log('chat getSettings : ', params);
    const {
      chat_item,
      emoji,
      chat_message,
      banned_words,
      banned_words_custom,
      chat_setting,
      // buff_custom,
      // emoji_custom,
    } = await $axios.get(`/api-tv/chat/${TV_REST_API_VERSION}`, { params });
    const { fx, buff } = chat_item.reduce(
      (accumulator, { item_type, item_cd, item_amt, item_img_url, item_area, item_effect }) => {
        if (!accumulator[item_type]) {
          accumulator[item_type] = {};
        }
        accumulator[item_type][item_cd] = {
          key: item_cd,
          price: item_amt,
          imgSrc: item_img_url,
          effect: item_effect,
          area: item_area,
        };

        return accumulator;
      },
      {}
    );

    const emojiList = emoji.reduce((accumulator, { emoji_group, emoji_nm, emoji_img_url }) => {
      if (!accumulator[emoji_group]) {
        accumulator[emoji_group] = {};
      }
      accumulator[emoji_group][emoji_nm] = {
        key: emoji_nm,
        imgSrc: emoji_img_url,
      };
      return accumulator;
    }, {});

    const messages = chat_message.reduce((accumulator, { message_desc, message_type, message_code }) => {
      if (!accumulator[message_type]) {
        accumulator[message_type] = {};
      }
      accumulator[message_type][message_code] = message_desc;

      return accumulator;
    }, {});

    const bannedWords = banned_words.map(({ word_text }) => word_text);
    const bannedWordsCustom = banned_words_custom.map(({ word_text }) => word_text);

    return {
      channelId,
      messages,
      bannedWords,
      bannedWordsCustom,
      fx,
      buff,
      emoji: emojiList,
      chatSettings: chat_setting,
    };
  },
  // 채팅 설정하기(전용채팅)
  async putChatUser(channelId, params) {
    await $axios.put(`/api-tv/chatset/${TV_REST_API_VERSION}/${channelId}/set/chatuser`, params);
  },
});
