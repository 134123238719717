import { TV_REST_API_VERSION } from '@/constants/apiVersion';
import { scheduleInfoMapper } from '@/mappings/room';

export default ($axios) => ({
  // 스케줄 정보 가져오기
  async getSchedule(schedule_sq) {
    const { schedule } = await $axios.get(`/api-tv/schedule/${TV_REST_API_VERSION}/${schedule_sq}`);
    return scheduleInfoMapper(schedule);
  },

  // 스케줄 초대링크를 통한 schedule_sq 조회
  async getUrlToScheduleInfo(url) {
    const { schedule_invitation } = await $axios.get(`/api-tv/schedule/${TV_REST_API_VERSION}/link/${url}`);

    return schedule_invitation;
  },
});
