import { TV_REST_API_VERSION } from '@/constants/apiVersion';

export default ($axios) => ({
  // 빌드 리스트 가져오기
  async getBuildAppLists(params) {
    const { build_app } = await $axios.get(`/api-tv/common/${TV_REST_API_VERSION}/buildapp`, {
      params,
    });

    return build_app;
  },

  // 초대링크를 통한 member_no 조회
  async getLinkUrl(url) {
    const { invitation } = await $axios.get(`/api-tv/common/${TV_REST_API_VERSION}/link/${url}`);

    return invitation;
  },

  /**
   * 타사용자 정보 조회
   */
  async getInfoMemberNo(memberNo) {
    const response = await $axios.get(`/api-tv/common/${TV_REST_API_VERSION}/member/${memberNo}`);
    return response.member;
  },

  // 리뷰 정보 가져오기
  async getReview(reviewSq) {
    const { review } = await $axios.get(`/api-tv/common/${TV_REST_API_VERSION}/review/${reviewSq}`);
    return review;
  },

  // 리뷰 등록
  async insertReview(params) {
    const response = await $axios.post(`/api-tv/common/${TV_REST_API_VERSION}/review`, params);
    return response;
  },
});
