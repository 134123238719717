import { APP_DOWNLOAD_ALERT_MESSAGES } from '@/constants/messages/alertMessages';

/**
 * singleton으로 관리
 */
const appDeepLink = (function () {
  let instance = null;

  function moveToApp($api, $isAndroid, $isiOS, $getters) {
    let pageBackgroundCheckTimer = null;
    let moveToStoreTimer = null;

    function clearTimer() {
      clearInterval(pageBackgroundCheckTimer);
      clearTimeout(moveToStoreTimer);
    }

    /**
     * 앱내 페이지로 이동하기위한 함수
     */
    function moveToAppPage({ androidUrl, iOSUrl } = { androidUrl: '', iOSUrl: '' }) {
      return new Promise((resolve, reject) => {
        clearTimer();

        // 모바일 웹페이지가 백그라운드로 진입하면 앱스토어로 하는 타이머 클리어
        if ($isAndroid || $isiOS) {
          pageBackgroundCheckTimer = setInterval(() => {
            if (document.webkitHidden || document.hidden) {
              clearTimer();
            }
          }, 200);
        }

        // 1.5초 후에 앱스토어로 이동
        moveToStoreTimer = setTimeout(async () => {
          clearInterval(pageBackgroundCheckTimer);
          try {
            if ($isAndroid) {
              await moveToAppStore();
              resolve();
            } else if ($isiOS) {
              // throw new Error(`${process.env.SERVICE_NAME} 앱 설치가 필요하다면 다운로드 버튼을 눌러보세요.`);
              throw new Error(window.$nuxt.$i18n.t('common.label-text.ios-download-desc'));
            }
          } catch (error) {
            reject(error);
          } finally {
            clearTimeout(moveToStoreTimer);
          }
        }, 1500);

        if ($isAndroid) {
          location.href = `${process.env.ANDROID_BASE_DEEP_LINK}${androidUrl}`;
        } else if ($isiOS) {
          location.href = `${process.env.iOS_BASE_DEEP_LINK}${iOSUrl}`;
        }
      });
    }

    /**
     * 앱스토어로 이동하기 위한 함수
     */
    async function moveToAppStore(buildType = null) {
      // 대응하지 않음
      // if ($getters['gds/getLocale'] !== 'kr') {
      //   return;
      // }
      const currentDomain = `${window.location.protocol}//${window.location.host}`;
      const storeAddressList = await $api.common.getBuildAppLists({
        build_env: currentDomain.includes('m-sb') ? 'SANDBOX' : 'LIVE',
      });

      let currentBuildType = null;

      if (buildType) {
        currentBuildType = buildType;
      } else {
        currentBuildType = $isAndroid ? 'ANDROID' : 'IOS';
      }

      let url = '';

      for await (const addressInfo of storeAddressList) {
        const { build_type, build_url } = addressInfo;

        if (build_type === currentBuildType) {
          if (!build_url.includes('play.google') && !build_url.includes('apps.apple')) {
            const controller = new AbortController();

            const abortTimer = setTimeout(() => {
              controller.abort(APP_DOWNLOAD_ALERT_MESSAGES.INVALID_ENTERPRISE_WIFI);
            }, 1000);

            try {
              const res = await fetch('https://tv-origin-qa.ppool.us', { signal: controller.signal });

              if (res) {
                clearTimeout(abortTimer);
                if (res.status !== 200) {
                  throw new Error(APP_DOWNLOAD_ALERT_MESSAGES.INVALID_ENTERPRISE_WIFI);
                }
              }
            } catch (error) {
              throw new Error(APP_DOWNLOAD_ALERT_MESSAGES.INVALID_ENTERPRISE_WIFI);
            }
          }
          url = build_url;
        }
      }

      // eslint-disable-next-line no-new
      return new Promise((resolve, reject) => {
        if (url === '') {
          reject(new Error(this.$t('common.message.invalid-download')));
        } else {
          location.href = url;
        }
      });
    }

    return { moveToAppPage, moveToAppStore };
  }

  function getInstance($api, $isAndroid, $isiOS, $getters) {
    if (!instance) {
      instance = moveToApp($api, $isAndroid, $isiOS, $getters);
    }
    return instance;
  }

  return { getInstance };
})();

const getInstance = appDeepLink.getInstance;

export { getInstance };
