import { TV_REST_API_VERSION } from '@/constants/apiVersion';

export default ($axios) => ({
  // 게시글 리스트 가져오기
  async getArticleLists(params) {
    const { article_list, total_contents } = await $axios.get(`/api-tv/article/${TV_REST_API_VERSION}`, {
      params,
    });

    return {
      articleLists: article_list,
      totalContents: total_contents,
    };
  },
  // 게시글 등록
  async insertArticle(params) {
    const response = await $axios.post(`/api-tv/article/${TV_REST_API_VERSION}`, params);
    return response;
  },
  // 이미지 업로드
  async imageUpload(params) {
    console.log(params);
    const formData = new FormData();
    formData.append('file', params.file);
    const response = await $axios.post(`/api-tv/article/${TV_REST_API_VERSION}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  },

  // 게시글 정보 가져오기
  async getArticleInfo(article_sq) {
    const { article_info } = await $axios.get(`/api-tv/article/${TV_REST_API_VERSION}/${article_sq}`);
    return article_info;
  },
  // 게시글 수정
  async updateArticle(article_sq, params) {
    const response = await $axios.put(`/api-tv/article/${TV_REST_API_VERSION}/${article_sq}`, params);

    return response;
  },
  // 게시글 삭제
  async deleteArticle(article_sq, article_pw) {
    const response = await $axios({
      method: 'delete',
      url: `/api-tv/article/${TV_REST_API_VERSION}/${article_sq}`,
      data: {
        article_pw,
      },
    });

    return response;
  },
  // 댓글 리스트 가져오기
  async getArticleCommentList(article_sq, params) {
    const { comment_list, total_contents } = await $axios.get(
      `/api-tv/article/${TV_REST_API_VERSION}/${article_sq}/comment`,
      {
        params,
      }
    );

    return {
      commentLists: comment_list,
      totalContents: total_contents,
    };
  },
  // 댓글 등록
  async createComment(article_sq, params) {
    const response = await $axios.post(`/api-tv/article/${TV_REST_API_VERSION}/${article_sq}/comment`, params);
    return response;
  },
  // 댓글 수정
  async updateArticleComment(article_sq, comment_sq, params) {
    const response = await $axios.put(
      `/api-tv/article/${TV_REST_API_VERSION}/${article_sq}/comment/${comment_sq}`,
      params
    );

    return response;
  },
  // 댓글 삭제
  async deleteArticleComment(article_sq, comment_sq, comment_pw) {
    const response = await $axios({
      method: 'delete',
      url: `/api-tv/article/${TV_REST_API_VERSION}/${article_sq}/comment/${comment_sq}`,
      data: {
        comment_pw,
      },
    });

    return response;
  },
});
