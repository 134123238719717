export const roomListMapper = (list) => {
  if (!list.length) {
    return list;
  } else {
    return list.map((room) => roomInfoMapper(room));
  }
};

export const roomInfoMapper = (info) => {
  if (!info) {
    return info;
  } else {
    return {
      sq: info.room_sq,
      scheduleSq: info.schedule_sq,
      title: info.room_nm,
      description: info.room_introduce,
      roomType: info.room_type,
      maxCnt: info.room_max_cnt,
      pwYn: info.room_pw_yn,
      pw: info.room_pw,
      userCnt: info.room_user_cnt,
      hostCnt: info.room_host_cnt,
      guestCnt: info.room_guest_cnt,
      spotlightCnt: info.room_spotlight_cnt,
      joinRequestYn: info.join_request_yn,
      showYn: info.show_yn,
      lockYn: info.lock_yn,
      closeYn: info.close_yn,
      serverType: info.server_type,
      regDt: info.reg_dt,
      scheduleDt: info.schedule_dt,
      share: roomShareMapper(info.share),
      member: roomMemberList(info.member),
      canvas: info.canvas,
    };
  }
};

export const scheduleInfoMapper = (info) => {
  return {
    ...roomInfoMapper(info),
    alarmCnt: info.alarm_cnt,
    member: info.member ? info.member.map((member) => scheduleRoomMemberMapper(member)) : [],
    alarmMember: info.alarm_member ? info.alarm_member.map((member) => scheduleRoomMemberMapper(member)) : [],
  };
};

const roomMemberList = (list) => {
  if (!list || !list.length) {
    return [];
  } else {
    return list.map((member) => roomMemberMapper(member));
  }
};

const roomMemberMapper = (info) => {
  return {
    sq: info.room_sq,
    memberNo: info.member_no,
    guestSq: info.guest_sq,
    nickname: info.nickname,
    profileImg: info.profile_img,
    profileBgColor: info.profile_bg_color,
    managerYn: info.manager_yn,
    audioYn: info.audio_yn,
    videoYn: info.video_yn,
    regDt: info.reg_dt,
    speakerYn: info.speaker_yn,
    info: memberMapper(info.info),
  };
};

const scheduleRoomMemberMapper = (info) => {
  const { info: memberInfo } = info;

  return {
    memberNo: memberInfo.member_no || 0,
    guestSq: memberInfo.guest_sq || 0,
    nickname: memberInfo.nickname,
    profileImg: memberInfo.profile_img,
    profileBgColor: memberInfo.profile_bg_color,
    managerYn: memberInfo.manager_yn,
    audioYn: memberInfo.audio_yn,
    videoYn: memberInfo.video_yn,
    regDt: memberInfo.reg_dt,
    speakerYn: memberInfo.speaker_yn,
    info: memberMapper(info.info),
  };
};

const memberMapper = (info) => {
  if (info == null) {
    return {
      memberNo: null,
      nickname: null,
      profileImg: null,
      regDt: null,
      updDt: null,
    };
  }

  return {
    memberNo: info.member_no,
    nickname: info.nickname,
    profileImg: info.profile_img,
  };
};

export const inviteInfo = (info) => {
  if (!info) {
    return info;
  } else {
    return {
      sq: info.room_sq,
      linkUrl: info.link_url,
      uniq: info.save_yn,
      regDt: info.reg_dt,
    };
  }
};

const roomShareMapper = (info) => {
  if (!info) {
    return info;
  } else {
    return {
      shareSq: info.share_sq,
      roomSq: info.room_sq,
      memberNo: info.member_no,
      guestSq: info.guest_sq,
      shareType: info.share_type,
      shareKey: info.share_key,
      shareTime: info.share_time,
      shareState: info.share_state,
      videoType: info.video_ratio,
    };
  }
};
