
export default {
  layout: 'errorLayout',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      errorFlag: 0,
      errorImage: '/images/error-img.png',
      errorTitle: '',
      errorMessage: '',
    };
  },
  head() {
    return {
      ...this.$nuxtI18nSeo(),
    };
  },
  mounted() {
    this.initError();
  },
  methods: {
    initError() {
      console.log(this.error);
      if (this.error) {
        if (this.error.statusCode === 404) {
          location.href = this.localePath('/');
          // this.errorMessage = this.error.message
        } else if (this.error.statusCode === 500) {
          console.log(this.error);
          if (this.error.response_code) {
            this.errorMessage = this.error.response_message;
          } else {
            this.errorMessage = this.error.message;
          }
        } else {
          this.errorMessage = this.error.message;
        }
      }
    },
  },
};
