import { coverageLanguages } from '@/utils/localeUtil';
import { getCookie } from '../utils/auth';

export default function (app) {
  try {
    const {
      route,
      app: { router, i18n },
      redirect,
    } = app;

    const cookie = getCookie(app.req, app.res);
    const cookieLocale = (cookie.get('LOCALE') || '').toLowerCase();
    const browserLocales = app?.req?.headers['accept-language']?.split(';')[0]?.split(',') || [];

    const params = route.path.split('/');
    const routeLocale = (params?.[1] || '').toLowerCase();

    let redirectPath;
    let changingLocale;
    const localeCodes = coverageLanguages;
    const isServiceLangCode = routeLocale && coverageLanguages.includes(routeLocale);

    // 라우터 로케일이 유효하지 않을 경우
    if (!localeCodes.includes(routeLocale)) {
      // 쿠키 로케일이 유효할 경우
      if (localeCodes.includes(cookieLocale)) {
        changingLocale = cookieLocale;
      } else {
        let change = '';
        browserLocales.forEach((locale) => {
          if (change) {
            return;
          }
          // 중국어 관련 대응
          const lang = locale.replace('_', '-').toLowerCase();
          const splitLang = lang.split('-')[0];
          if (coverageLanguages.includes(lang)) {
            change = lang;
          }

          if (coverageLanguages.includes(splitLang)) {
            change = splitLang;
          }
        });

        if (change) {
          changingLocale = change;
        }
      }

      let fullPath = route.fullPath;
      if (isServiceLangCode && changingLocale) {
        // url 에 첫 파라미터가 iso 규격의 language 코드이면
        // 기존 language 코드를 변경되는 language 코드로 대체 후 path 생성
        let params = route.fullPath.split('/');
        params = params.slice(2);
        fullPath = `/${params.join('/')}`;
      }
      if (changingLocale) {
        fullPath = fullPath.charAt(fullPath.length - 1) === '/' ? fullPath.slice(0, -1) : fullPath;
        redirectPath = router.resolve(`${changingLocale}${fullPath}`).href;
      }
    } else if (routeLocale !== cookieLocale) {
      // 라우터 로케일이 쿠키 로케일과 다를 경우
      i18n.setLocale(routeLocale);
    }

    if (redirectPath) {
      cookie.set('LOCALE', changingLocale.toUpperCase(), { domain: 'ppool.us', path: '/' });
      redirect(redirectPath);
    } else if (routeLocale && isServiceLangCode) {
      cookie.set('LOCALE', routeLocale.toUpperCase(), { domain: 'ppool.us', path: '/' });
    }
    return Promise.resolve(true);
  } catch (e) {
    console.log(e);
    return Promise.resolve(true);
  }
}
