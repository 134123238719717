const state = () => {
  return {
    roomId: '',
    sources: [],
  };
};

const getters = {
  roomId: (state) => state.roomId,
  sources: (state) => state.sources,
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  editSource({ commit }, payload) {
    commit('editSource', payload);
  },
  pushSource({ commit }, payload) {
    commit('pushSource', payload);
  },
  deleteSource({ commit }, payload) {
    commit('deleteSource', payload);
  },
  enabledTrack({ commit }, payload) {
    commit('enabledTrack', payload);
  },
};

const mutations = {
  reset(state) {
    state.roomId = '';
    state.sources = [];
  },
  editSource(state, payload) {
    const { memberNo, guestSq, producerId, kind, track, type } = payload;

    const prevInfo = state.sources.filter((source) => memberNo === source.memberNo && guestSq === source.guestSq);

    if (type === 'product') {
      const prevSameTrack = prevInfo[0].tracks.filter((track) => track.kind === kind);

      if (kind === 'video') {
        const stream = prevInfo[0].stream;
        stream.removeTrack(prevSameTrack[0].track);
        stream.addTrack(track);

        const idx = state.sources.findIndex((source) => source.memberNo === memberNo && source.guestSq === guestSq);
        if (idx !== -1) {
          const trackIdx = state.sources[idx].tracks.findIndex((track) => track.kind === kind);
          if (trackIdx !== -1) {
            state.sources[idx].tracks.splice(trackIdx, 1, {
              kind,
              producerId,
              track,
              type,
            });
          }
        }
      }
    }
  },
  pushSource(state, payload) {
    const { memberNo, guestSq, producerId, kind, track, type } = payload;
    console.log('0=', state.sources);
    const prevInfo = state.sources.filter((source) => memberNo === source.memberNo && guestSq === source.guestSq);

    let stream = null;

    if (prevInfo.length) {
      stream = prevInfo[0].stream;
      if (type === 'product') {
        const prevSameTrack = prevInfo[0].tracks.filter((track) => track.kind === kind);
        const prevNotSameTrack = prevInfo[0].tracks.filter((track) => track.kind !== kind);

        if (prevSameTrack.length) {
          prevInfo[0].tracks = prevNotSameTrack.concat({
            kind,
            producerId,
            track,
            type,
          });
        } else {
          prevInfo[0].tracks.push({
            kind,
            producerId,
            track,
            type,
          });
        }

        if (kind === 'video') {
          stream.addTrack(track);
        }
      }

      if (type === 'consume') {
        stream = prevInfo[0].stream;
        const prevSameTrack = prevInfo[0].tracks.filter((track) => track.kind === kind);
        const prevNotSameTrack = prevInfo[0].tracks.filter((track) => track.kind !== kind);

        if (!prevSameTrack.length) {
          prevInfo[0].tracks.push({
            kind,
            producerId,
            track,
            type,
          });
          stream.addTrack(track);
        } else if (prevSameTrack[0].type === type) {
          prevInfo[0].tracks = prevNotSameTrack.concat({
            kind,
            producerId,
            track,
            type,
          });
          stream.addTrack(track);
        }
      }
    } else {
      stream = new MediaStream();
      if (type === 'product' && kind === 'video') {
        stream.addTrack(track);
      }
      if (type === 'consume') {
        stream.addTrack(track);
      }

      state.sources.push({
        memberNo: memberNo ? memberNo.toString() : '',
        guestSq: guestSq ? guestSq.toString() : '',
        type,
        stream,
        tracks: [
          {
            kind,
            producerId,
            track,
            type,
          },
        ],
      });
    }
  },
  deleteSource(state, payload) {
    const { memberNo, guestSq, producerId } = payload;
    // const remainSorces = []
    const idx = state.sources.findIndex((source) => source.memberNo === memberNo && source.guestSq === guestSq);
    const source = state.sources[idx];
    if (idx !== -1) {
      console.log('#######source', source);
      const trackIdx = source.tracks.findIndex((track) => track.producerId === producerId);
      if (trackIdx !== -1) {
        const track = source.tracks[trackIdx];
        console.log('######track', track);
        source.stream.removeTrack(track.track);
        source.tracks.splice(trackIdx, 1);
        if (source.tracks.length === 0) {
          state.sources.splice(idx, 1);
        }
      }
    }
    /*
    state.sources.forEach((source) => {
      if (source.memberNo !== memberNo && source.guestSq !== guestSq) {
        remainSorces.push(source)
      } else {
        const remainTrack = source.tracks.filter(
          (track) => track.producerId !== producerId
        )

        if (remainTrack.length) {
          source.tracks = remainTrack
          remainSorces.push(source)
        }
      }
    })
    */

    // state.sources = remainSorces
  },
  enabledTrack(state, payload) {
    const { memberNo, guestSq, kind, track, type } = payload;

    if (type === 'product') {
      const idx = state.sources.findIndex((source) => source.memberNo === memberNo && source.guestSq === guestSq);
      if (idx !== -1) {
        const trackIdx = state.sources[idx].tracks.findIndex((track) => track.kind === kind);
        if (trackIdx !== -1) {
          state.sources[idx].tracks[trackIdx].enabled = track.enabled;
        }
      }
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
