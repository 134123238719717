import Cookies from 'cookie-universal';
import {
  DOMAIN,
  OLD_REFRESH_TOKEN,
  OLD_TOKEN_HD,
  OLD_TOKEN_PLD,
  OLD_TOKEN_SIGN,
  REFRESH_TOKEN,
  TOKEN,
} from '@/constants/cookies';
import cookies from '@/utils/cookies';

export function getCookie(req, res) {
  return req && res ? Cookies(req, res) : cookies;
}

/**
 * Set Token
 * @param {string} token
 * @param {Date} expires
 * @param req
 * @param res
 * @returns {*}
 */
export function setToken(token = '', expires, { req, res } = {}) {
  const cookie = getCookie(req, res);
  cookie.set(TOKEN, token, {
    domain: process.env.COOKIE_DOMAIN,
    path: '/',
    expires,
  });
}

export function getToken({ req, res } = {}) {
  const cookie = getCookie(req, res);

  const oldToken = `${cookie.get(OLD_TOKEN_HD) || ''}.${cookie.get(OLD_TOKEN_PLD) || ''}.${
    cookie.get(OLD_TOKEN_SIGN) || ''
  }`;

  return cookie.get(TOKEN) || (oldToken !== '..' ? oldToken : undefined);
}

export function removeToken({ req, res } = {}) {
  console.log('removeToken', req, res);
  const cookie = getCookie(req, res);
  const opts = {
    domain: process.env.COOKIE_DOMAIN,
    path: '/',
  };
  cookie.remove(TOKEN, opts);
  cookie.remove(REFRESH_TOKEN, opts);
  cookie.remove(OLD_TOKEN_HD, opts);
  cookie.remove(OLD_TOKEN_PLD, opts);
  cookie.remove(OLD_TOKEN_SIGN, opts);
  cookie.remove(OLD_REFRESH_TOKEN, opts);
}

/**
 * Set Refresh Token
 * @param {string} token
 * @param {Date} expires
 * @param req
 * @param res
 * @returns {*}
 */
export function setRefreshToken(token = '', expires, { req, res } = {}) {
  const cookie = getCookie(req, res);
  cookie.set(REFRESH_TOKEN, token, {
    domain: process.env.COOKIE_DOMAIN,
    path: '/',
    expires,
  });
}

export function getRefreshToken({ req, res } = {}) {
  const cookie = getCookie(req, res);
  return cookie.get(REFRESH_TOKEN) || cookie.get(OLD_REFRESH_TOKEN);
}

function getRedirectUrl(redirectUrl) {
  const domain = cookies.get(DOMAIN);
  return `?redirect_url=${encodeURIComponent(domain + (redirectUrl === '/' ? '' : redirectUrl))}`;
}

export function goLogin(redirectUrl = location.href) {
  location.href = `${process.env.LOGIN_URL}/auth/login?redirect_url=${encodeURIComponent(redirectUrl)}`;
}

/**
 * 로그인 URL
 * @param {string} redirectUrl
 * @returns {string}
 */
export function getLoginUrl(redirectUrl) {
  return `${process.env.LOGIN_URL}/auth/login${getRedirectUrl(redirectUrl)}`;
}

/**
 * 회원가입 URL
 * @param {string} redirectUrl
 * @returns {string}
 */
export function getJoinUrl(redirectUrl) {
  return `${process.env.LOGIN_URL}/register${getRedirectUrl(redirectUrl)}`;
}

/*
  guest cookie 추가
*/
export function setGuestCookie(guestSq, { req, res } = {}) {
  console.log('set GuestCookie', guestSq, req, res);
  const cookie = getCookie(req, res);
  console.log(cookie);
  cookie.set('guestSq', guestSq, {
    path: '/',
    maxAge: 60 * 60 * 24,
    secure: true,
  });
}

/*
  guest NickName cookie 추가
*/
export function setGuestNickNameCookie(guestNickName, { req, res } = {}) {
  console.log('set setGuestNickNameCookie', guestNickName, req, res);
  const cookie = getCookie(req, res);
  console.log(cookie);
  cookie.set('guestNickName', guestNickName, {
    path: '/',
    maxAge: 60 * 60 * 24,
    secure: true,
  });
}

export function getGuestNickNameCookie() {
  const cookie = getCookie();
  return cookie.get('guestNickName');
}
