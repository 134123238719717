import { getToken } from '@/utils/auth';
import { TV_REST_API_VERSION } from '@/constants/apiVersion';
import dayjs from '@/utils/dayjs';

export default ($axios) => ({
  async getMembers(params) {
    const mergeParams = Object.assign(
      {
        page_offset: 1,
        page_size: 10,
      },
      params
    );

    const { member } = await $axios.get(`/api-tv/member/${TV_REST_API_VERSION}/`, {
      params: mergeParams,
    });

    return member;
  },
  /**
   * 사용자 정보 조회
   * @param {string} memberNo
   * @returns {Promise<AxiosResponse<T>>}
   */
  async getInfo() {
    const response = await $axios.get(`/api-tv/member/${TV_REST_API_VERSION}/`);

    return {
      memberInfo: response.member,
    };
  },

  /**
   * 타사용자 정보 조회
   * @param {string} memberNo
   * @returns {Promise<AxiosResponse<T>>}
   */
  async getInfoMemberNo(memberNo) {
    const response = await $axios.get(`/api-tv/member/${TV_REST_API_VERSION}/${memberNo}`, {
      params: {
        nocache: dayjs().millisecond(),
      },
    });

    return {
      memberInfo: response.member,
    };
  },
  // 회원 닉네임으로 가져오기(닉네임 중복체크)
  async getTvMemberNickname(params) {
    return await $axios.get(`/api-tv/member/${TV_REST_API_VERSION}/nickname`, {
      params,
    });
  },
  // 회원 정보 수정 하기(닉네임)
  async putTvMemberNickname(memberNo, params) {
    return await $axios.put(`/api-tv/member/${TV_REST_API_VERSION}/${memberNo}/nickname`, params);
  },
  // 프로필 이미지 업로드 하기
  async postProfileImageUpload(channelId, params) {
    console.log(params);
    const formData = new FormData();
    formData.append('file', params.file);

    return await $axios.post(`/api-tv/member/${TV_REST_API_VERSION}/${channelId}/profileUpload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  /**
   * 최근 본 영상
   * @param memberNo
   * @param params
   */
  async getLatestViewLists(memberNo, params = { page_offset: 1, page_size: 20, type: 'all' }) {
    const response = await $axios.get(`/api-tv/member/${TV_REST_API_VERSION}/${memberNo}/latestview`, {
      params,
    });

    let watchedLists = [];

    switch (params.type) {
      case 'all':
        // eslint-disable-next-line no-case-declarations
        const latestVod = response.latest_vod.map((vod) => vod);
        // eslint-disable-next-line no-case-declarations
        const latestLive = response.latest_live.map((live) => live);
        watchedLists = latestVod.concat(latestLive);
        break;
      case 'live':
        // eslint-disable-next-line no-case-declarations
        const latestLives = (response[`latest_${params.type}`] || []).map((vod) => vod);
        watchedLists = latestLives;
        break;
    }

    return {
      watchedLists,
      loadComplete: true,
    };
  },
  // 회원 Channel Id로 가져오기(방송 id 중복체크)
  getTvMemberBychannelId(params) {
    return $axios.get(`/api-tv/member/${TV_REST_API_VERSION}/channelid`, {
      params,
    });
  },
  // 회원 정보 등록 하기
  postTvMemberInfo(memberNo, params) {
    const token = getToken();
    return $axios.post(`/api-tv/member/${TV_REST_API_VERSION}/${memberNo}`, {
      access_token: token,
      ...params,
    });
  },

  /**
   * 사용자 프로필 조회
   * @param {string} linkUrl
   */
  async getProfileInfoBylinkUrl(linkUrl) {
    const { member } = await $axios.get(`/api-tv/member/${TV_REST_API_VERSION}/link/${linkUrl}`);

    return member;
  },
});
