import { TV_REST_API_VERSION } from '@/constants/apiVersion';

export default ($axios) => ({
  /**
   * 로그인 사용자 정보 조회
   * @returns {Promise<AxiosResponse<T>>}
   */
  async session(token) {
    let url = `/api-auth/${TV_REST_API_VERSION}/common/session`;

    const headers = {
      'X-Forwarded-Host': process.env.X_FORWARDED_HOST,
    };

    // 로컬 개발이 아닌 경우
    if (token && !process.env.IS_LOCAL) {
      const { type, encoding, content } = await this._internalSessionCheck(token);

      url = url.replace('/api-auth', '/api-i-auth');

      headers['x-owner-content-type'] = type;
      headers['x-owner-content-encoding'] = encoding;
      headers['x-owner-content'] = content;
    }

    const {
      value: { member },
    } = await $axios.get(url, { headers });

    return member;
  },
  /**
   * Token 재 발급
   * @param {string} refreshToken
   * @param {string} ip
   * @returns {Promise<AxiosResponse<T>>}
   */
  async renew(refreshToken, ip = '127.0.0.1') {
    // try {
    const data = `refresh_token=${refreshToken}&ip=${ip}`;
    const {
      value: { access_token, refresh_token, expire_in },
      // result,
      // message,
    } = await $axios.post(`/api-auth/${TV_REST_API_VERSION}/common/renew?`, data, {
      headers: {
        'X-Forwarded-Host': process.env.X_FORWARDED_HOST,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    const access = access_token;
    const refresh = refresh_token;
    const expires = expire_in;

    return {
      access,
      refresh,
      expires,
    };
    // } catch (err) {
    //   console.log('renew error : ', err)
    // }
  },
  async _internalSessionCheck(token) {
    const { response_data } = await $axios.get('/api-i-mega-auth/v5/tokens/user_token/check', {
      params: {
        access_token: token,
      },
    });

    const type = response_data['x-owner-content-type'];
    const encoding = response_data['x-owner-content-encoding'];
    const content = response_data['x-owner-content'];

    return {
      type,
      encoding,
      content,
    };
  },
});
