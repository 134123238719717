import merge from 'deepmerge';
import { getLanguageFromServer } from './common';

export default async () => {
  return merge(
    {
      title: `Let's meet at P.Pool!`,
      intro: {
        contents: {
          // Zone 01
          'zone-01-catch-phrase':
            'Completely FREE video chat with no time limits!<br />Easier and fun social video communication service',
          'zone-01-app-download': 'Download Mobile App',
          // Zone 04
          'zone-04-catch-phrase-1': 'Enjoy fun contents with your tribe!',
          'zone-04-catch-phrase-2': 'High quality audio and super high definition video.',
          // Zone 05
          'zone-05-catch-phrase-1': 'More the merrier!',
          'zone-05-catch-phrase-2': 'Communication with 1,025 participants in a single meeting for FREE!',
          'zone-05-catch-phrase-3': 'From concerts',
          'zone-05-catch-phrase-4': 'to online webinars',
          'zone-05-catch-phrase-5': 'Enjoy it as if you were there!',
          'zone-05-catch-phrase-6': 'Start your online video meetings with P.Pool',
          // Zone 06
          'zone-06-catch-phrase-1': 'Create a video chat room easily and quickly.<br>& enjoy online gatherings!',
          'zone-06-catch-phrase-2': 'Pick the chat type that<br>best suits your gathering.',
          'zone-06-catch-phrase-3':
            'From small gatherings to webinars,<br/>Start your video chat right away, just 3 seconds!',
          'zone-06-catch-phrase-4': 'Liven up the mood<br/>with unique video effects.',
          'zone-06-catch-phrase-5':
            'Have more fun with personalized AR avatars<br/>and a variety of virtual backgrounds!',
          'zone-06-catch-phrase-6': 'Unlimited free<br/>Video Chats!',
          'zone-06-catch-phrase-7': 'No need to rush, communicate in a new way!',
          // Zone 07
          'zone-07-catch-phrase-1': 'You can get P.Pool for any device.',
          'zone-07-catch-phrase-2': 'Try using the PC version of P.Pool on your desktop.',
        },
      },
      terms: {
        'label-text': {
          service: 'Terms of Use',
          privacy: 'Privacy Policy',
          'safe-guard': 'Juvenile Protection Policy',
          policy: 'Operation Policy',
          'no-login': '비회원 개인 정보 수집 약관',
          'service-link': 'STOVE Terms of Use Link',
          'privacy-link': 'STOVE Privacy Policy Link',
          'policy-link': 'STOVE Operation Policy Link',
        },
      },
      'google-drive': {
        'label-text': {
          title: 'Google Drive File Sharing',
          'login-desc': 'Sign in with Google',
          'drive-desc': 'Convert Microsoft PowerPoint, <br/>PDF files into images and view the files together.',
          'upload-wait-desc': 'Preparing to View File Together. <br/>Please wait a moment.',
          'upload-done-desc':
            'File View Together is ready. <br/>Please move to the app to proceed with viewing together!',
          'upload-cancel-desc': 'Do you want to cancel the file upload?',
          'upload-permission-desc':
            'You do not have permission to share files on screen share.<br>Please check on the P.Pool app again.',
          '10mb-desc': 'Only files smaller than 10MB can be uploaded.',
          '100page-desc': 'Only files below 100 slides can be uploaded.',
        },
        'label-button': {
          login: 'Sign In',
          'upload-cancel': 'Cancel Upload',
          'move-app': 'Go to App',
        },
      },
      common: {
        'label-button': {
          'app-download': 'Download App',
          download: 'Download',
          'show-app': 'View with App',
          'find-room-in-app': 'Find other chats in the app',
          'join-app': 'Join with App',
          accept: 'Yes',
          deny: 'No',
        },
        'label-text': {
          'not-install-desc': `Don't have the P.Pool app?`,
          private: 'Private',
          'close-youtube': 'Do you want to end the <br/>YouTube Watch Together session?',
          'ios-download-desc': 'If you need to install the P.Pool app, please tap the download button.',
        },
        message: {
          'not-find-user': 'Cannot verify user information.',
          'expired-user': 'Cannot verify user information.',
          'expired-review': '유효하지 않은 리뷰 설문입니다.',
          'room-close': 'Video chat has ended or is invalid.',
          'invalid-download': 'The service is currently being prepared.',
          'youtube-mute-desc':
            'Due to browser policy, the YouTube video has been muted. Touch the YouTube video to unmute.',
        },
        footer: {
          'label-button': {
            'about-service': 'About Us',
            'terms-of-use': 'Terms of Use',
            'privacy-policy': 'Privacy Policy',
            'operation-policy': 'Operation Policy',
            'customer-service': 'Customer Service',
            'use-notice': 'Support',
          },
        },
        'room-card': {
          'label-text': {
            'just-now': 'Newly started',
            days: 'Started {days} days ago',
            hours: 'Started {hours} hours ago',
            minutes: 'Started {minutes} mins ago',
          },
          contents: {
            'others-scheduled-room-desc-default': 'Fun times await you with P.Pool',
            'alarm-register-member': ' user(s) are waiting for the video chat. ',
            'alarm-list-empty': "Don't have any notified video chat users.",
          },
        },
      },
      modal: {
        review: {
          'label-text': {
            score: 'score',
          },
          'label-button': {
            submit: 'Completed!',
            feedback: 'Submit feedback',
          },
          contents: {
            submit: 'Thank you for your time and your answers.',
            'submit-desc': 'Every answer you give will help make P.Pool better.',
            'score-desc': 'On a scale from 0-10, how likely are you to recommend P.Pool to a friend or colleague?',
            bad: 'Not at all Likely',
            good: 'Extremely Likely',
            'select-score': 'Please move left and right to select the score.',
          },
          placeholder: {
            'review-input': 'Is there anything specific that P.Pool can do to improve your experience?',
          },
        },
      },
      'date-format': {
        'room-card-date': 'LLL',
        'scheduled-room-modal-card-date': 'LL',
        'scheduled-room-modal-card-time': 'LT',
      },
    },
    await getLanguageFromServer('en')
  );
};
