import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ff78448 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _044afd3e = () => interopDefault(import('../src/pages/articleList.vue' /* webpackChunkName: "pages/articleList" */))
const _e2a91c58 = () => interopDefault(import('../src/pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _4ddf47f8 = () => interopDefault(import('../src/pages/article/view.vue' /* webpackChunkName: "pages/article/view" */))
const _558ae290 = () => interopDefault(import('../src/pages/article/write.vue' /* webpackChunkName: "pages/article/write" */))
const _83705b7c = () => interopDefault(import('../src/pages/lic/license.vue' /* webpackChunkName: "pages/lic/license" */))
const _67d6d39c = () => interopDefault(import('../src/pages/review/reviewcomplete.vue' /* webpackChunkName: "pages/review/reviewcomplete" */))
const _5ab49f8b = () => interopDefault(import('../src/pages/watch/googledrive/googleclose.vue' /* webpackChunkName: "pages/watch/googledrive/googleclose" */))
const _6790f7f2 = () => interopDefault(import('../src/pages/chat/hate-speech-detection/_roomId.vue' /* webpackChunkName: "pages/chat/hate-speech-detection/_roomId" */))
const _04244560 = () => interopDefault(import('../src/pages/sch/wait/_sq.vue' /* webpackChunkName: "pages/sch/wait/_sq" */))
const _950de0be = () => interopDefault(import('../src/pages/watch/googledrive/_roomId.vue' /* webpackChunkName: "pages/watch/googledrive/_roomId" */))
const _c99e2078 = () => interopDefault(import('../src/pages/watch/preview/_videoId.vue' /* webpackChunkName: "pages/watch/preview/_videoId" */))
const _229774dc = () => interopDefault(import('../src/pages/watch/set/_roomId.vue' /* webpackChunkName: "pages/watch/set/_roomId" */))
const _4f55a8de = () => interopDefault(import('../src/pages/draw/_localDrawBoard.vue' /* webpackChunkName: "pages/draw/_localDrawBoard" */))
const _c58d7256 = () => interopDefault(import('../src/pages/error/_message.vue' /* webpackChunkName: "pages/error/_message" */))
const _1093f88e = () => interopDefault(import('../src/pages/invite/_link.vue' /* webpackChunkName: "pages/invite/_link" */))
const _3fb1b97c = () => interopDefault(import('../src/pages/msg/_message.vue' /* webpackChunkName: "pages/msg/_message" */))
const _74c4a736 = () => interopDefault(import('../src/pages/pageroom/_roomId.vue' /* webpackChunkName: "pages/pageroom/_roomId" */))
const _d321587e = () => interopDefault(import('../src/pages/paginationroom/_roomId.vue' /* webpackChunkName: "pages/paginationroom/_roomId" */))
const _3c16c60b = () => interopDefault(import('../src/pages/profile/_channelId.vue' /* webpackChunkName: "pages/profile/_channelId" */))
const _061bb824 = () => interopDefault(import('../src/pages/review/_reviewSq.vue' /* webpackChunkName: "pages/review/_reviewSq" */))
const _2d70d0d8 = () => interopDefault(import('../src/pages/roominvite/_link.vue' /* webpackChunkName: "pages/roominvite/_link" */))
const _0766f968 = () => interopDefault(import('../src/pages/rooms/_roomId.vue' /* webpackChunkName: "pages/rooms/_roomId" */))
const _70ed5c2c = () => interopDefault(import('../src/pages/sch/_link.vue' /* webpackChunkName: "pages/sch/_link" */))
const _1887ce82 = () => interopDefault(import('../src/pages/watch/_roomId.vue' /* webpackChunkName: "pages/watch/_roomId" */))
const _4d97e02e = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _1ff78448,
    props: false,
    name: "index___en"
  }, {
    path: "/ja",
    component: _1ff78448,
    props: false,
    name: "index___ja"
  }, {
    path: "/ko",
    component: _1ff78448,
    props: false,
    name: "index___ko"
  }, {
    path: "/zh-cn",
    component: _1ff78448,
    props: false,
    name: "index___zh-cn"
  }, {
    path: "/zh-tw",
    component: _1ff78448,
    props: false,
    name: "index___zh-tw"
  }, {
    path: "/en/articleList",
    component: _044afd3e,
    props: false,
    name: "articleList___en"
  }, {
    path: "/en/terms",
    component: _e2a91c58,
    props: false,
    name: "terms___en"
  }, {
    path: "/ja/articleList",
    component: _044afd3e,
    props: false,
    name: "articleList___ja"
  }, {
    path: "/ja/terms",
    component: _e2a91c58,
    props: false,
    name: "terms___ja"
  }, {
    path: "/ko/articleList",
    component: _044afd3e,
    props: false,
    name: "articleList___ko"
  }, {
    path: "/ko/terms",
    component: _e2a91c58,
    props: false,
    name: "terms___ko"
  }, {
    path: "/zh-cn/articleList",
    component: _044afd3e,
    props: false,
    name: "articleList___zh-cn"
  }, {
    path: "/zh-cn/terms",
    component: _e2a91c58,
    props: false,
    name: "terms___zh-cn"
  }, {
    path: "/zh-tw/articleList",
    component: _044afd3e,
    props: false,
    name: "articleList___zh-tw"
  }, {
    path: "/zh-tw/terms",
    component: _e2a91c58,
    props: false,
    name: "terms___zh-tw"
  }, {
    path: "/en/article/view",
    component: _4ddf47f8,
    props: false,
    name: "article-view___en"
  }, {
    path: "/en/article/write",
    component: _558ae290,
    props: false,
    name: "article-write___en"
  }, {
    path: "/en/lic/license",
    component: _83705b7c,
    props: false,
    name: "lic-license___en"
  }, {
    path: "/en/review/reviewcomplete",
    component: _67d6d39c,
    props: false,
    name: "review-reviewcomplete___en"
  }, {
    path: "/ja/article/view",
    component: _4ddf47f8,
    props: false,
    name: "article-view___ja"
  }, {
    path: "/ja/article/write",
    component: _558ae290,
    props: false,
    name: "article-write___ja"
  }, {
    path: "/ja/lic/license",
    component: _83705b7c,
    props: false,
    name: "lic-license___ja"
  }, {
    path: "/ja/review/reviewcomplete",
    component: _67d6d39c,
    props: false,
    name: "review-reviewcomplete___ja"
  }, {
    path: "/ko/article/view",
    component: _4ddf47f8,
    props: false,
    name: "article-view___ko"
  }, {
    path: "/ko/article/write",
    component: _558ae290,
    props: false,
    name: "article-write___ko"
  }, {
    path: "/ko/lic/license",
    component: _83705b7c,
    props: false,
    name: "lic-license___ko"
  }, {
    path: "/ko/review/reviewcomplete",
    component: _67d6d39c,
    props: false,
    name: "review-reviewcomplete___ko"
  }, {
    path: "/zh-cn/article/view",
    component: _4ddf47f8,
    props: false,
    name: "article-view___zh-cn"
  }, {
    path: "/zh-cn/article/write",
    component: _558ae290,
    props: false,
    name: "article-write___zh-cn"
  }, {
    path: "/zh-cn/lic/license",
    component: _83705b7c,
    props: false,
    name: "lic-license___zh-cn"
  }, {
    path: "/zh-cn/review/reviewcomplete",
    component: _67d6d39c,
    props: false,
    name: "review-reviewcomplete___zh-cn"
  }, {
    path: "/zh-tw/article/view",
    component: _4ddf47f8,
    props: false,
    name: "article-view___zh-tw"
  }, {
    path: "/zh-tw/article/write",
    component: _558ae290,
    props: false,
    name: "article-write___zh-tw"
  }, {
    path: "/zh-tw/lic/license",
    component: _83705b7c,
    props: false,
    name: "lic-license___zh-tw"
  }, {
    path: "/zh-tw/review/reviewcomplete",
    component: _67d6d39c,
    props: false,
    name: "review-reviewcomplete___zh-tw"
  }, {
    path: "/en/watch/googledrive/googleclose",
    component: _5ab49f8b,
    props: false,
    name: "watch-googledrive-googleclose___en"
  }, {
    path: "/ja/watch/googledrive/googleclose",
    component: _5ab49f8b,
    props: false,
    name: "watch-googledrive-googleclose___ja"
  }, {
    path: "/ko/watch/googledrive/googleclose",
    component: _5ab49f8b,
    props: false,
    name: "watch-googledrive-googleclose___ko"
  }, {
    path: "/zh-cn/watch/googledrive/googleclose",
    component: _5ab49f8b,
    props: false,
    name: "watch-googledrive-googleclose___zh-cn"
  }, {
    path: "/zh-tw/watch/googledrive/googleclose",
    component: _5ab49f8b,
    props: false,
    name: "watch-googledrive-googleclose___zh-tw"
  }, {
    path: "/en/chat/hate-speech-detection/:roomId?",
    component: _6790f7f2,
    props: true,
    name: "chat-hate-speech-detection-roomId___en"
  }, {
    path: "/en/sch/wait/:sq?",
    component: _04244560,
    props: true,
    name: "sch-wait-sq___en"
  }, {
    path: "/en/watch/googledrive/:roomId?",
    component: _950de0be,
    props: true,
    name: "watch-googledrive-roomId___en"
  }, {
    path: "/en/watch/preview/:videoId?",
    component: _c99e2078,
    props: true,
    name: "watch-preview-videoId___en"
  }, {
    path: "/en/watch/set/:roomId?",
    component: _229774dc,
    props: true,
    name: "watch-set-roomId___en"
  }, {
    path: "/ja/chat/hate-speech-detection/:roomId?",
    component: _6790f7f2,
    props: true,
    name: "chat-hate-speech-detection-roomId___ja"
  }, {
    path: "/ja/sch/wait/:sq?",
    component: _04244560,
    props: true,
    name: "sch-wait-sq___ja"
  }, {
    path: "/ja/watch/googledrive/:roomId?",
    component: _950de0be,
    props: true,
    name: "watch-googledrive-roomId___ja"
  }, {
    path: "/ja/watch/preview/:videoId?",
    component: _c99e2078,
    props: true,
    name: "watch-preview-videoId___ja"
  }, {
    path: "/ja/watch/set/:roomId?",
    component: _229774dc,
    props: true,
    name: "watch-set-roomId___ja"
  }, {
    path: "/ko/chat/hate-speech-detection/:roomId?",
    component: _6790f7f2,
    props: true,
    name: "chat-hate-speech-detection-roomId___ko"
  }, {
    path: "/ko/sch/wait/:sq?",
    component: _04244560,
    props: true,
    name: "sch-wait-sq___ko"
  }, {
    path: "/ko/watch/googledrive/:roomId?",
    component: _950de0be,
    props: true,
    name: "watch-googledrive-roomId___ko"
  }, {
    path: "/ko/watch/preview/:videoId?",
    component: _c99e2078,
    props: true,
    name: "watch-preview-videoId___ko"
  }, {
    path: "/ko/watch/set/:roomId?",
    component: _229774dc,
    props: true,
    name: "watch-set-roomId___ko"
  }, {
    path: "/zh-cn/chat/hate-speech-detection/:roomId?",
    component: _6790f7f2,
    props: true,
    name: "chat-hate-speech-detection-roomId___zh-cn"
  }, {
    path: "/zh-cn/sch/wait/:sq?",
    component: _04244560,
    props: true,
    name: "sch-wait-sq___zh-cn"
  }, {
    path: "/zh-cn/watch/googledrive/:roomId?",
    component: _950de0be,
    props: true,
    name: "watch-googledrive-roomId___zh-cn"
  }, {
    path: "/zh-cn/watch/preview/:videoId?",
    component: _c99e2078,
    props: true,
    name: "watch-preview-videoId___zh-cn"
  }, {
    path: "/zh-cn/watch/set/:roomId?",
    component: _229774dc,
    props: true,
    name: "watch-set-roomId___zh-cn"
  }, {
    path: "/zh-tw/chat/hate-speech-detection/:roomId?",
    component: _6790f7f2,
    props: true,
    name: "chat-hate-speech-detection-roomId___zh-tw"
  }, {
    path: "/zh-tw/sch/wait/:sq?",
    component: _04244560,
    props: true,
    name: "sch-wait-sq___zh-tw"
  }, {
    path: "/zh-tw/watch/googledrive/:roomId?",
    component: _950de0be,
    props: true,
    name: "watch-googledrive-roomId___zh-tw"
  }, {
    path: "/zh-tw/watch/preview/:videoId?",
    component: _c99e2078,
    props: true,
    name: "watch-preview-videoId___zh-tw"
  }, {
    path: "/zh-tw/watch/set/:roomId?",
    component: _229774dc,
    props: true,
    name: "watch-set-roomId___zh-tw"
  }, {
    path: "/en/draw/:localDrawBoard?",
    component: _4f55a8de,
    props: true,
    name: "draw-localDrawBoard___en"
  }, {
    path: "/en/error/:message?",
    component: _c58d7256,
    props: true,
    name: "error-message___en"
  }, {
    path: "/en/invite/:link?",
    component: _1093f88e,
    props: true,
    name: "invite-link___en"
  }, {
    path: "/en/msg/:message?",
    component: _3fb1b97c,
    props: true,
    name: "msg-message___en"
  }, {
    path: "/en/pageroom/:roomId?",
    component: _74c4a736,
    props: true,
    name: "pageroom-roomId___en"
  }, {
    path: "/en/paginationroom/:roomId?",
    component: _d321587e,
    props: true,
    name: "paginationroom-roomId___en"
  }, {
    path: "/en/profile/:channelId?",
    component: _3c16c60b,
    props: true,
    name: "profile-channelId___en"
  }, {
    path: "/en/review/:reviewSq?",
    component: _061bb824,
    props: true,
    name: "review-reviewSq___en"
  }, {
    path: "/en/roominvite/:link?",
    component: _2d70d0d8,
    props: true,
    name: "roominvite-link___en"
  }, {
    path: "/en/rooms/:roomId?",
    component: _0766f968,
    props: true,
    name: "rooms-roomId___en"
  }, {
    path: "/en/sch/:link?",
    component: _70ed5c2c,
    props: true,
    name: "sch-link___en"
  }, {
    path: "/en/watch/:roomId?",
    component: _1887ce82,
    props: true,
    name: "watch-roomId___en"
  }, {
    path: "/ja/draw/:localDrawBoard?",
    component: _4f55a8de,
    props: true,
    name: "draw-localDrawBoard___ja"
  }, {
    path: "/ja/error/:message?",
    component: _c58d7256,
    props: true,
    name: "error-message___ja"
  }, {
    path: "/ja/invite/:link?",
    component: _1093f88e,
    props: true,
    name: "invite-link___ja"
  }, {
    path: "/ja/msg/:message?",
    component: _3fb1b97c,
    props: true,
    name: "msg-message___ja"
  }, {
    path: "/ja/pageroom/:roomId?",
    component: _74c4a736,
    props: true,
    name: "pageroom-roomId___ja"
  }, {
    path: "/ja/paginationroom/:roomId?",
    component: _d321587e,
    props: true,
    name: "paginationroom-roomId___ja"
  }, {
    path: "/ja/profile/:channelId?",
    component: _3c16c60b,
    props: true,
    name: "profile-channelId___ja"
  }, {
    path: "/ja/review/:reviewSq?",
    component: _061bb824,
    props: true,
    name: "review-reviewSq___ja"
  }, {
    path: "/ja/roominvite/:link?",
    component: _2d70d0d8,
    props: true,
    name: "roominvite-link___ja"
  }, {
    path: "/ja/rooms/:roomId?",
    component: _0766f968,
    props: true,
    name: "rooms-roomId___ja"
  }, {
    path: "/ja/sch/:link?",
    component: _70ed5c2c,
    props: true,
    name: "sch-link___ja"
  }, {
    path: "/ja/watch/:roomId?",
    component: _1887ce82,
    props: true,
    name: "watch-roomId___ja"
  }, {
    path: "/ko/draw/:localDrawBoard?",
    component: _4f55a8de,
    props: true,
    name: "draw-localDrawBoard___ko"
  }, {
    path: "/ko/error/:message?",
    component: _c58d7256,
    props: true,
    name: "error-message___ko"
  }, {
    path: "/ko/invite/:link?",
    component: _1093f88e,
    props: true,
    name: "invite-link___ko"
  }, {
    path: "/ko/msg/:message?",
    component: _3fb1b97c,
    props: true,
    name: "msg-message___ko"
  }, {
    path: "/ko/pageroom/:roomId?",
    component: _74c4a736,
    props: true,
    name: "pageroom-roomId___ko"
  }, {
    path: "/ko/paginationroom/:roomId?",
    component: _d321587e,
    props: true,
    name: "paginationroom-roomId___ko"
  }, {
    path: "/ko/profile/:channelId?",
    component: _3c16c60b,
    props: true,
    name: "profile-channelId___ko"
  }, {
    path: "/ko/review/:reviewSq?",
    component: _061bb824,
    props: true,
    name: "review-reviewSq___ko"
  }, {
    path: "/ko/roominvite/:link?",
    component: _2d70d0d8,
    props: true,
    name: "roominvite-link___ko"
  }, {
    path: "/ko/rooms/:roomId?",
    component: _0766f968,
    props: true,
    name: "rooms-roomId___ko"
  }, {
    path: "/ko/sch/:link?",
    component: _70ed5c2c,
    props: true,
    name: "sch-link___ko"
  }, {
    path: "/ko/watch/:roomId?",
    component: _1887ce82,
    props: true,
    name: "watch-roomId___ko"
  }, {
    path: "/zh-cn/draw/:localDrawBoard?",
    component: _4f55a8de,
    props: true,
    name: "draw-localDrawBoard___zh-cn"
  }, {
    path: "/zh-cn/error/:message?",
    component: _c58d7256,
    props: true,
    name: "error-message___zh-cn"
  }, {
    path: "/zh-cn/invite/:link?",
    component: _1093f88e,
    props: true,
    name: "invite-link___zh-cn"
  }, {
    path: "/zh-cn/msg/:message?",
    component: _3fb1b97c,
    props: true,
    name: "msg-message___zh-cn"
  }, {
    path: "/zh-cn/pageroom/:roomId?",
    component: _74c4a736,
    props: true,
    name: "pageroom-roomId___zh-cn"
  }, {
    path: "/zh-cn/paginationroom/:roomId?",
    component: _d321587e,
    props: true,
    name: "paginationroom-roomId___zh-cn"
  }, {
    path: "/zh-cn/profile/:channelId?",
    component: _3c16c60b,
    props: true,
    name: "profile-channelId___zh-cn"
  }, {
    path: "/zh-cn/review/:reviewSq?",
    component: _061bb824,
    props: true,
    name: "review-reviewSq___zh-cn"
  }, {
    path: "/zh-cn/roominvite/:link?",
    component: _2d70d0d8,
    props: true,
    name: "roominvite-link___zh-cn"
  }, {
    path: "/zh-cn/rooms/:roomId?",
    component: _0766f968,
    props: true,
    name: "rooms-roomId___zh-cn"
  }, {
    path: "/zh-cn/sch/:link?",
    component: _70ed5c2c,
    props: true,
    name: "sch-link___zh-cn"
  }, {
    path: "/zh-cn/watch/:roomId?",
    component: _1887ce82,
    props: true,
    name: "watch-roomId___zh-cn"
  }, {
    path: "/zh-tw/draw/:localDrawBoard?",
    component: _4f55a8de,
    props: true,
    name: "draw-localDrawBoard___zh-tw"
  }, {
    path: "/zh-tw/error/:message?",
    component: _c58d7256,
    props: true,
    name: "error-message___zh-tw"
  }, {
    path: "/zh-tw/invite/:link?",
    component: _1093f88e,
    props: true,
    name: "invite-link___zh-tw"
  }, {
    path: "/zh-tw/msg/:message?",
    component: _3fb1b97c,
    props: true,
    name: "msg-message___zh-tw"
  }, {
    path: "/zh-tw/pageroom/:roomId?",
    component: _74c4a736,
    props: true,
    name: "pageroom-roomId___zh-tw"
  }, {
    path: "/zh-tw/paginationroom/:roomId?",
    component: _d321587e,
    props: true,
    name: "paginationroom-roomId___zh-tw"
  }, {
    path: "/zh-tw/profile/:channelId?",
    component: _3c16c60b,
    props: true,
    name: "profile-channelId___zh-tw"
  }, {
    path: "/zh-tw/review/:reviewSq?",
    component: _061bb824,
    props: true,
    name: "review-reviewSq___zh-tw"
  }, {
    path: "/zh-tw/roominvite/:link?",
    component: _2d70d0d8,
    props: true,
    name: "roominvite-link___zh-tw"
  }, {
    path: "/zh-tw/rooms/:roomId?",
    component: _0766f968,
    props: true,
    name: "rooms-roomId___zh-tw"
  }, {
    path: "/zh-tw/sch/:link?",
    component: _70ed5c2c,
    props: true,
    name: "sch-link___zh-tw"
  }, {
    path: "/zh-tw/watch/:roomId?",
    component: _1887ce82,
    props: true,
    name: "watch-roomId___zh-tw"
  }, {
    path: "/zh-cn/*",
    component: _4d97e02e,
    props: false,
    name: "all___zh-cn"
  }, {
    path: "/zh-tw/*",
    component: _4d97e02e,
    props: false,
    name: "all___zh-tw"
  }, {
    path: "/ja/*",
    component: _4d97e02e,
    props: false,
    name: "all___ja"
  }, {
    path: "/en/*",
    component: _4d97e02e,
    props: false,
    name: "all___en"
  }, {
    path: "/ko/*",
    component: _4d97e02e,
    props: false,
    name: "all___ko"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
