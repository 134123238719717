import axios from 'axios';

export const getLanguageFromServer = async (language_code) => {
  let ret = {};
  try {
    ret =
      (
        await axios.get(`${process.env.API_TV_URL}/common/v1.0/lang/latest`, {
          params: { key_type: 'MOBILE_WEB', language_code },
        })
      )?.data || {};
  } catch {}

  console.log(language_code);
  return ret;
};
