// import cookies from '@/utils/cookies'
// import { APP } from '@/constants/cookies'
// import Cookies from 'cookie-universal'
// import { getToken } from '@/utils/auth'

const actions = {
  // async nuxtServerInit(
  //   { commit, dispatch, getters },
  //   { req, res, redirect, app: { $stomp }, route }
  // ) {
  //   // const cookie = Cookies(req, res)
  //   // app settings
  //   // const cookie = Cookies(req, res)
  //   // const app = cookie.get(APP)
  //   // close beta 암호 입력 관련
  //   // console.log('######## route', route)
  //   // const betaValidate = cookie.get('validate')
  //   // if (!betaValidate && !route.name.includes('helper-streamKey')) {
  //   //   redirect('/closebetagate')
  //   // }
  //   // 토큰 존재 && store user 정보 없는 경우
  //   const token = getToken({ req, res })
  //   // const guestSq = cookie.get('guestSq')
  //   console.log('######## token', token)
  //   if (token && !getters['user/memberNo']) {
  //     await dispatch('user/getInfo', token)
  //     /*
  //     if (guestSq) {
  //       cookie.remove('guestSq')
  //     }
  //     */
  //     // const {
  //     //   user: { memberNo, channelId },
  //     // } = await dispatch('user/getInfo', token)
  //     // 신규 가입자 채널 정보 없을시 이용약관 페이지 로 이동
  //     // if (memberNo && !channelId) {
  //     //   redirect('/terms')
  //     // }
  //   }
  //   // token 미존재 && guest 값 있고, guest 정보 안받아왔을때
  //   /*
  //   if (!token && guestSq && !getters['guest/guestSq']) {
  //     try {
  //       await dispatch('guest/getInfo', guestSq)
  //     } catch (err) {
  //       cookie.remove('guestSq')
  //       redirect('/error/session')
  //     }
  //   }
  //   // token 미존재 && guest값 없을경우, guest값 신청
  //   if (!token && !guestSq) {
  //     await dispatch('guest/postInfo', { req, res })
  //   }
  //   */
  // },
};

export default {
  actions,
};
