const middleware = {}

middleware['AuthService'] = require('../src/middleware/AuthService.js')
middleware['AuthService'] = middleware['AuthService'].default || middleware['AuthService']

middleware['init'] = require('../src/middleware/init.js')
middleware['init'] = middleware['init'].default || middleware['init']

middleware['locale-refresh'] = require('../src/middleware/locale-refresh.js')
middleware['locale-refresh'] = middleware['locale-refresh'].default || middleware['locale-refresh']

middleware['RouterChangeGuard'] = require('../src/middleware/RouterChangeGuard.js')
middleware['RouterChangeGuard'] = middleware['RouterChangeGuard'].default || middleware['RouterChangeGuard']

export default middleware
