export default ($axios) => ({
  /**
   * @param {string[]} speech
   * @param {boolean} isCheckVulgarismCounts
   * @param {boolean} isCheckCrossValidation
   */
  async hateSpeechDetection(speech, isCheckVulgarismCounts = false, isCheckCrossValidation = false) {
    const result = await $axios.post(
      '/sgp-hsd-lime/inference',
      {
        inputs: speech,
        check_vulgarism_counts: isCheckVulgarismCounts,
        check_cross_validation: isCheckCrossValidation,
      },
      {
        baseURL: 'https://tv-media-dev.ppool.us:10184',
      }
    );

    const { outputs } = result;

    return outputs;
  },
});
