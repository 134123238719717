import { removeToken } from '@/utils/auth';

function getUserTemplate() {
  return {
    memberNo: null,
    nickname: null,
    profileImg: null,
    regDt: null,
    updDt: null,
    // level: 0,
    // mileage: 0,
    // channel: {
    //   openYn: false,
    //   liveYn: false,
    // },
    // cash: 0, // 현재 존재 하지 않음
    // channelId: null,
    // nicknameUpdateDate: null,
    // teamLeaveDt: null,
    // channelInfo: {},
    os: null,
  };
}

const state = () => getUserTemplate();

const getters = {
  memberInfo: (state) => state,
  memberNo: (state) => state.memberNo,
  nickname: (state) => state.nickname,
  profileImg: (state) => state.profileImg,
  // cash: (state) => state.cash,
  // level: (state) => state.level,
  // mileage: (state) => state.mileage,
  // avatar: (state) => state.avatar,
  // channelId: (state) => state.channelId,
  // nicknameUpdateDate: (state) => state.nicknameUpdateDate,
  // teamLeaveDt: (state) => state.teamLeaveDt,
  // channelInfo: (state) => state.channelInfo,
};

const mutations = {
  SET_USER: (
    state,
    {
      memberNo,
      nickname,
      profileImg,
      regDt,
      updDt,
      // cash,
      // mileage,
      // avatar,
      // channelId,
      // level,
      // nicknameUpdateDate,
      // teamLeaveDt,
      // channelInfo,
    }
  ) => {
    state.memberNo = memberNo;
    state.nickname = nickname;
    state.profileImg = profileImg;
    state.regDt = regDt;
    state.updDt = updDt;
    // state.cash = cash || 0
    // state.mileage = mileage || 0
    // state.level = level || 0
    // state.avatar = avatar
    // state.channelId = channelId
    // state.nicknameUpdateDate = nicknameUpdateDate
    // state.teamLeaveDt = teamLeaveDt
    // state.channelInfo = channelInfo || {}
  },
};

const actions = {
  getInfo({ commit }, token) {
    // console.log('############# getInfo', token)
    // const $api = this.$api
    const user = getUserTemplate();
    // try {
    //   const member = await $api.auth.session(token)
    //   // 신규 가입자의 경우 기본정보 없음....
    //   const { memberInfo } = await $api.member.getInfo()
    //   if (memberInfo) {
    //     user = {
    //       memberNo: memberInfo.member_no,
    //       nickname: memberInfo.nickname,
    //       profileImg: memberInfo.profile_img,
    //       regDt: memberInfo.reg_dt,
    //       updDt: memberInfo.upd_dt,
    //       // cash: memberInfo.cash,
    //       // mileage: memberInfo.mileage_info,
    //       // avatar: memberInfo.profile_img_id,
    //       // channelId: memberInfo.channel_id,
    //       // level: memberInfo.level_amt,
    //       // nicknameUpdateDate: memberInfo.nickname_upd_dt,
    //       // teamLeaveDt: memberInfo.team_leave_dt,
    //       // channelInfo: memberInfo.channel_info,
    //     }
    //     commit('SET_USER', user)
    //   } else {
    //     user.memberNo = member.member_no
    //     commit('SET_USER', { memberNo: member.member_no })
    //   }
    // } catch (error) {
    //   // TODO: 인증 오류 처리
    //   console.error('Token Error', error)
    // }

    return { user };
  },
  logout({ commit }) {
    removeToken();
    commit('SET_USER', getUserTemplate());
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
