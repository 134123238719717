import { EventEmitter } from 'eventemitter3';
import socketIO from 'socket.io-client';

export default class StompClient extends EventEmitter {
  constructor() {
    super();
    this.msgUrl = `${process.env.MSG_SERVER_URL}`;
    this.client = null;
    this.reconnectTimer = null;
  }

  connect(login, passcode, memberNo, guestSq) {
    // console.log('############## stomp', login, passcode, memberNo, guestSq);
    return new Promise((resolve, reject) => {
      const self = this;

      this.client = socketIO.connect(this.msgUrl, {
        transports: ['websocket'],
        reconnection: true,
      });

      this.client.on('connect', () => {
        clearInterval(this.reconnectTimer);
        self.subscribe(`user.${memberNo}`);
        resolve();
      });

      this.client.on('reconnecting', () => {
        console.log('reconnecting');
      });

      this.client.on('reconnect', () => {
        console.log('reconnect');
      });

      this.client.on('disconnect', () => {
        console.log('disconnect');
      });

      this.client.on('error', () => {
        console.log('error');
      });

      this.client.on('connection_error', () => {
        console.log('connection error');

        if (this.client.connected) {
          this.client.disconnect();
        }

        this.reconnectTimer = setInterval(() => {
          this.client.connect();
        }, 1000);
      });

      this.client.on('message', (msg) => {
        self.messageCallback(msg);
      });
    });
  }

  subscribe(path) {
    this.client.emit('subscribe', { path });
  }

  unsubscribe(path) {
    this.client.emit('unsubscribe', { path });
  }

  messageCallback(msg) {
    const { code, data, member_no, guest_sq, room_sq } = JSON.parse(msg) || {
      code: '',
    };
    const eventName = code.split('_').slice(2).join('_');

    super
      .eventNames()
      .filter((item) => item.split(':')[1] === eventName)
      .forEach((event) => {
        super.emit(event, data, member_no, guest_sq, room_sq);
      });
  }

  disconnect() {
    this.client.disconnect();
  }
}
