export default ($axios) => ({
  async getGds() {
    try {
      const result = await $axios.get(`${process.env.STOVE_TERMS_API}/gds/v2/client/policy/total`, {
        params: {
          policy_grp: 'onstove',
          client_lang: 'ko',
          include_coverages: true,
        },
      });

      return result.res_data;
    } catch (err) {
      // console.log('error catch gds', err);
      return {
        is_default: false,
        nation: 'KR',
        regulation: 'KOREA',
        timezone: 'Asia/Seoul',
        utc_offset: 540,
        lang: 'ko',
        coverages: ['de', 'en', 'es', 'fr', 'id', 'it', 'ja', 'ko', 'pt', 'th', 'vi', 'zh-cn', 'zh-tw'],
      };
    }
  },
});
