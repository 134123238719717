import { TV_REST_API_VERSION } from '@/constants/apiVersion';

export default ($axios) => ({
  /**
   * 로그인 사용자 정보 조회
   * @returns {Promise<AxiosResponse<T>>}
   */
  async getCuration() {
    const url = `/api-tv/youtube/${TV_REST_API_VERSION}/curation`;

    const { curation_list } = await $axios.get(url);
    return curation_list;
  },
});
