exports.APP = 'app';
exports.DOMAIN = 'domain';
exports.TOKEN = 'SUAT';
exports.REFRESH_TOKEN = 'SURT';
exports.LANGUAGE = 'language';
exports.OLD_TOKEN_HD = 'HD';
exports.OLD_TOKEN_PLD = 'PLD';
exports.OLD_TOKEN_SIGN = 'SIGN';
exports.OLD_REFRESH_TOKEN = 'RFT';
// 채팅
exports.TRANSLATE = 'translate-lang';
