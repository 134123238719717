
export default {
  name: 'PopupWrapper',

  props: {
    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
      contents: '',
    };
  },

  mounted() {
    this.$bus.$on(`open:${this.name}`, (contents) => {
      this.isOpen = true;
      if (contents) this.contents = contents;
    });
    this.$bus.$on(`close:${this.name}`, () => (this.isOpen = false));
  },

  beforeDestroy() {
    this.$bus.$off(`open:${this.name}`);
    this.$bus.$off(`close:${this.name}`);
  },
};
