import { TV_REST_API_VERSION } from '@/constants/apiVersion';
import { roomInfoMapper, inviteInfo } from '@/mappings/room';
import { GOOGLE_DRIVE_ALERT_MESSAGES } from '@/constants/messages/alertMessages';
import cookies from '@/utils/cookies';

export default ($axios) => ({
  // 방 리스트 가져오기
  async getRoomLists(params) {
    const { room_list, total_contents } = await $axios.get(`/api-tv/room/${TV_REST_API_VERSION}/`, {
      params,
    });

    return {
      roomLists: room_list,
      totalContents: total_contents,
    };
  },
  // 방 생성
  async create(params) {
    const { room_info } = await $axios.post(`/api-tv/room/${TV_REST_API_VERSION}`, params);
    return roomInfoMapper(room_info);
  },
  // room 정보 삭제처리
  async deleteRoom(room_sq) {
    const response = await $axios.put(`/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/closeyn`);

    return response;
  },
  // 방 정보 가져오기
  async getRoomInfo(sq) {
    const { room_info } = await $axios.get(`/api-tv/room/${TV_REST_API_VERSION}/${sq}`);
    return roomInfoMapper(room_info);
  },
  // 방 정보 가져오기(비밀번호 조건 포함)
  async getRoomInfoByPwd(sq, pwd) {
    const { room_info } = await $axios.get(`/api-tv/room/${TV_REST_API_VERSION}/${sq}/password/${pwd}`);
    return room_info;
  },
  // 방 초대 링크 조회
  async getInvitation(sq) {
    const { room_invitation } = await $axios.get(`/api-tv/room/${TV_REST_API_VERSION}/${sq}/invitation`);

    return inviteInfo(room_invitation);
  },
  // 방 초대링크 생성 / 수정  / 재발급 등
  async postInvitation(sq, params) {
    const { room_invitation } = await $axios.post(`/api-tv/room/${TV_REST_API_VERSION}/${sq}/invitation`, params);

    return inviteInfo(room_invitation);
  },
  // 방 초대링크를 통한 room_sq 조회
  async getUrlToRoomInfo(url) {
    const { room_invitation } = await $axios.get(`/api-tv/room/${TV_REST_API_VERSION}/link/${url}`);

    return inviteInfo(room_invitation);
  },
  // 방 방송 시작/종료
  async putOnAir(room_sq, guest_sq, audio_yn, video_yn) {
    const response = await $axios.put(`/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/member`, {
      guest_sq,
      audio_yn,
      video_yn,
    });
    return response;
  },
  // 게스트 방 입장(방송하기)
  async postOnAir(room_sq, guest_sq, guest_nickname, guest_token) {
    const response = await $axios.post(
      `/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/member`,
      {
        room_sq,
        guest_sq,
        guest_nickname,
      },
      {
        headers: {
          Authorization: `jwt ${guest_token}`,
        },
      }
    );
    return response;
  },
  // 방 퇴장(방송종료)
  async deleteOnAir(room_sq, guest_sq, guest_token) {
    const response = await $axios({
      method: 'delete',
      url: `/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/member`,
      data: {
        room_sq,
        guest_sq,
      },
      headers: {
        Authorization: `jwt ${guest_token}`,
      },
    });

    return response;
  },

  // 함께보기 시작
  async insertRoomShare(room_sq, share_link, video_ratio, member_no, guest_sq, force_out = 0) {
    const response = await $axios.post(`/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/share`, {
      share_link,
      video_ratio,
      member_no,
      guest_sq,
      force_out,
    });

    return response;
  },

  // 함께보기 시간기록
  async updateRoomShareTime(room_sq, share_time, q, member_no, guest_sq) {
    const response = await $axios.put(`/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/share/time`, {
      share_time,
      q,
      member_no,
      guest_sq,
    });

    return response;
  },

  // 함께보기 상태기록
  async updateRoomShareState(room_sq, share_time, share_state, q, member_no, guest_sq) {
    const response = await $axios.put(`/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/share/state`, {
      share_time,
      share_state,
      q,
      member_no,
      guest_sq,
    });

    return response;
  },

  // 함께보기 종료
  async deleteRoomShare(room_sq, q, member_no, guest_sq) {
    const response = await $axios({
      method: 'delete',
      url: `/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/share`,
      data: {
        room_sq,
        q,
        member_no,
        guest_sq,
      },
    });

    return response;
  },

  // 그리기 정보 전달
  async insertRoomDraw(room_sq, member_no, q) {
    const response = await $axios.post(`/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/draw`, {
      room_sq,
      member_no,
      q,
    });

    return response;
  },

  // 그리기 정보 전달
  async updateRoomDraw(room_sq, member_no, q, draw) {
    const response = await $axios.put(`/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/draw`, {
      room_sq,
      member_no,
      q,
      draw,
    });

    return response;
  },

  // 그리기 정보 조회
  async getRoomDrawInfo(room_sq, memberNo) {
    let token = '';

    if (parseInt(memberNo) === 0) {
      token = cookies.get('guest_token');
    }

    const response = await $axios.get(`/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/draw`, {
      params: {
        member_no: memberNo,
      },
      headers: {
        Authorization: `jwt ${token}`,
      },
    });

    return response;
  },

  // 그리기 API 호출 테스트
  // async testRoomDraw(room_sq, member_no, q, draw) {
  //   await console.log('testRoomDraw', room_sq, member_no, q, draw)
  // },

  // 파일 함께보기 upload
  async insertRoomFileView(room_sq, file, member_no, guest_sq) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('member_no', member_no);
    formData.append('guest_sq', guest_sq);
    const response = await $axios.post(
      `/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/fileview/googledrive`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    if (response.response_code === 50074) {
      throw new Error(GOOGLE_DRIVE_ALERT_MESSAGES.INVALID_FILE_PAGE);
    }

    return response;
  },

  // 파일업로드 취소
  async cancelRoomFileView(room_sq, file_name, member_no, guest_sq) {
    const response = await $axios.put(`/api-tv/room/${TV_REST_API_VERSION}/${room_sq}/fileview/cancel`, {
      file_name,
      member_no,
      guest_sq,
    });

    return response;
  },
});
