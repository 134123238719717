function getAllowTemplate() {
  return {
    isVideo: false,
    isAudio: false,
  };
}

const state = () => getAllowTemplate();

const getters = {
  allowInfo: (state) => state,
  isVideo: (state) => state.isVideo,
  isAudio: (state) => state.isAudio,
};

const mutations = {
  reset(state) {
    state.isVideo = false;
    state.isAudio = false;
  },
  setAllowMedia: (state, { isVideo, isAudio }) => {
    state.isVideo = isVideo;
    state.isAudio = isAudio;
  },
};

const actions = {
  reset({ commit }) {
    commit('reset');
  },
  setAllowMedia({ commit }, payload) {
    commit('setAllowMedia', payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
