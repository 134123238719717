import { roomInfoMapper } from '@/mappings/room';

function getRoomInfoTemplate() {
  return {
    accessToken: null,
    tzOffset: null,
    pageSize: null,
    totalPage: null,
    page: null,
    offset: null,
    pageSort: null,
    searchText: null,
    sq: null,
    title: null,
    maxCnt: null,
    pwYn: null,
    showYn: null,
    memberNo: null,
    regDt: null,
    lockYn: null,
    closeYn: null,
    scheduleDt: null,
    member: [],
    manager: null,
    inviteInfo: null,
    share: null,
    newyear: [],
    canvas: null,
  };
}

const state = () => getRoomInfoTemplate();

const getters = {
  roomInfo: (state) => state,
  roomInfoCanvas: (state) => state.canvas,
  roomInviteInfo: (state) => state.inviteInfo,
  roomShare: (state) => state.share,
  roomNewyear: (state) => state.newyear,
};

const actions = {
  reset({ commit }) {
    commit('pushRoomInfo', getRoomInfoTemplate());
  },
  pushRoomInfo({ commit }, payload) {
    commit('pushRoomInfo', roomInfoMapper(payload));
  },
  async getRoomInfo({ commit }, roomId) {
    const $api = this.$api;
    const response = await $api.room.getRoomInfo(roomId);
    commit('pushRoomInfo', response);
  },
  updateInvitation({ commit }, payload) {
    commit('updateInviteInfo', payload);
  },
};

const mutations = {
  pushRoomInfo(
    state,
    {
      accessToken,
      tzOffset,
      pageSize,
      totalPage,
      page,
      offset,
      pageSort,
      searchText,
      sq,
      title,
      maxCnt,
      pwYn,
      showYn,
      memberNo,
      regDt,
      lockYn,
      closeYn,
      scheduleDt,
      member,
      manager,
      share,
      newyear,
      canvas,
    }
  ) {
    state.accessToken = accessToken;
    state.tzOffset = tzOffset;
    state.pageSize = pageSize;
    state.totalPage = totalPage;
    state.page = page;
    state.offset = offset;
    state.pageSort = pageSort;
    state.searchText = searchText;
    state.sq = sq;
    state.title = title;
    state.maxCnt = maxCnt;
    state.pwYn = pwYn;
    state.showYn = showYn;
    state.memberNo = memberNo;
    state.regDt = regDt;
    state.lockYn = lockYn;
    state.closeYn = closeYn;
    state.scheduleDt = scheduleDt;
    state.member = member;
    state.manager = manager;
    state.share = share;
    state.newyear = newyear;
    state.canvas = canvas;
  },
  updateInviteInfo(state, payload) {
    state.inviteInfo = payload;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
