import Vue from 'vue';

import PopupWrapper from '@/components/mobile/Popups/PopupWrapper.vue';

const modalPlugin = {
  installed: false,
  install(Vue) {
    if (this.installed) {
      return;
    }

    this.installed = true;

    Vue.prototype.$modal = {
      open: (name, contents) => {
        Vue.prototype.$bus.$emit(`open:${name}`, contents);
      },
      close: (name) => {
        Vue.prototype.$bus.$emit(`close:${name}`);
      },
    };

    Vue.component('PopupWrapper', PopupWrapper);
  },
};

Vue.use(modalPlugin);
